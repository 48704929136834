body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: url(/images/bg.png) repeat;
}

.App {
  text-align: center; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-title {
  font-size: 1.5em; }

.App-intro {
  font-size: large; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.deep-blue-bgc {
  background-color: #1B242F; }

.navbar {
  border-bottom: 2px solid #24dbe1; }

.nav-link {
  color: white !important; }

.nav-link:hover {
  color: rgba(255, 255, 255, 0.5) !important; }

.navbar-brand:hover {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-white {
  color: white; }

.text-redish {
  color: #e1068a; }

.max-width-100 {
  max-width: 100%; }

.no-margins {
  margin: 0 0; }

.no-padding {
  padding: 0 0; }


.default-row {
  height: 93vh;
  /*Medium screen sizes*/ }
  @media (min-width: 768px) {
    .default-row .main-right {
      overflow: auto;
      max-height: 93vh; }
    .default-row .main-right {
      text-align: left; } }

.profile-pic {
  width: 285px;
  height: auto;
  border-radius: 25%;
  margin-bottom: 1rem; }

.contact-items a {
  color: #1b242f; }

.contact-items svg {
  font-size: 2rem; }

.contact-items span {
  margin-left: 1.25rem; }

.section {
  margin: .5rem 0; }
  .section h3 {
    margin-bottom: 1rem; }

.default-row #about .card-body, .default-row #technology .card-body, .default-row #contact .card-body {
  padding: 1rem;
  border: 1px solid #439fe6; }

.default-row #technology thead th {
  border-top: none;
  border-bottom: 2px solid #439fe6; }

.default-row #technology table {
  margin-bottom: 0; }

.default-row #about .card-text {
  font-size: 17px; }

.default-row .main-left {
  margin-top: 4rem; }

.main-right .section h3 {
  color: #225378; }

.projects-wrapper {
  /*Medium screen sizes*/
  margin-bottom: 5rem; }
  @media (max-width: 769px) {
    .projects-wrapper .row {
      margin-right: 0;
      margin-left: 0; } }
  .projects-wrapper .title {
    margin-top: 1.25rem;
    color: #225378; }
  .projects-wrapper .project-title {
    margin-top: .5rem;
    margin-bottom: .5rem; }
  .projects-wrapper svg {
    font-size: 1.75rem; }
  .projects-wrapper .card img {
    /*Medium screen sizes*/
    box-shadow: 0px 0.25rem 1rem -5px #1B242F; }
    @media (min-width: 769px) {
      .projects-wrapper .card img {
        height: 250px; } }
  .projects-wrapper .card .card-body {
    margin-top: 1rem;
    margin-bottom: 1.25rem; }
  .projects-wrapper .container .row div {
    padding: .2rem .4rem; }
  .projects-wrapper .container .row .card {
    padding: 0 0;
    height: 100%; }

.no-padding {
  padding: 0 0; }

