@import '../Colors/Colors.scss';

.default-row{
  height: 93vh;

  /*Medium screen sizes*/
  @media (min-width: 768px) {
    .main-right{
      overflow: auto;
      max-height: 93vh;
    }

    .main-right{
      text-align: left;
    }
  }
}

.profile-pic{
  width: 285px;
  height: auto;
  border-radius: 25%;
  margin-bottom: 1rem;
}

.contact-items {
  a{
    color: $dark-blue;
  }
  svg{
    font-size: 2rem;
  }
  span{
    margin-left: 1.25rem;
  }
}

.section{
  margin: .5rem 0;

  h3{
    margin-bottom: 1rem;
  }
}

.default-row{
  #about,#technology,#contact{
    .card-body{
      padding: 1rem;
      border: 1px solid  $table-blue;
    }
  }

  #technology{
    thead{
      th{
        border-top: none;
        border-bottom: 2px solid $table-blue;
      }
    }
    table{
      margin-bottom: 0;
    }
  }

  #about{
    .card-text{
      font-size: 17px;
    }
  }

  .main-left{
    margin-top: 4rem;
  }

}


.main-right .section h3{
  color: $light-blue;
}


