@import "./components/Colors/Colors.scss";
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: $charcoal;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.deep-blue-bgc{
    background-color: $deep-blue;
}

nav{

}

.navbar{
    border-bottom: 2px solid $bright-blue;
}

.nav-link{
    color:white !important;
}
.nav-link:hover{
    color:rgba(255,255,255,.5) !important;
}

.navbar-brand:hover{
    color:rgba(255,255,255,.5) !important;
}

.text-white{
    color:white;
}

.text-redish{
    color: $hot-pink;
}

.max-width-100{
    max-width: 100%;
}

.no-margins{
    margin: 0 0;
}

.no-padding{
    padding: 0 0;
}
