@import '../Colors/Colors.scss';

.projects-wrapper{

  /*Medium screen sizes*/
  @media (max-width: 769px) {
    .row{
      margin-right: 0;
      margin-left: 0;
    }
  }
  .title{
    margin-top: 1.25rem;
    color: $light-blue;
  }

  .project-title {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  svg{
    font-size: 1.75rem;
  }

  .card{
    img{
      /*Medium screen sizes*/
      @media (min-width: 769px) {
        height: 250px;
      }
      -webkit-box-shadow: 0px 0.25rem 1rem -5px $deep-blue;
      -moz-box-shadow: 0px 0.25rem 1rem -5px $deep-blue;
      box-shadow: 0px 0.25rem 1rem -5px $deep-blue;
    }

    .card-body{
      margin-top: 1rem;
      margin-bottom: 1.25rem;
    }
  }

  .container{
    .row{
      div{
        padding: .2rem .4rem;
      }
      .card{
        padding: 0 0;
        height: 100%;
      }
    }
  }

  margin-bottom: 5rem;
}

.no-padding{
    padding: 0 0;
}